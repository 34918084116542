import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Table, Form, Col, Button, Row } from "react-bootstrap";
import { MdKeyboardArrowLeft } from "react-icons/md";
import { MdKeyboardArrowRight } from "react-icons/md";
import { FiSave } from "react-icons/fi";
import { MdAdd } from "react-icons/md";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  showLoader,
} from "../../../../redux/actions/templateActions";
import { useHistory } from "react-router-dom";
import Select from 'react-select';
import MarketFieldActionMenu from "../../../../components/action-menus/MarketFieldActionMenu";
import { createQRSpecification, getMPAFields, setStepTwoFields, updateQRSpecification,getQRSpecification, } from "../../../../redux/actions/qrSpecificationActions";
import arrowup from "../../../../assets/images/arrow-up.svg";
import arrowdown from "../../../../assets/images/arrow-down.svg";
import { toast } from "react-toastify";
import { SOMETHING_WENT_WRONG, UNAUTHORIZED_ACTION } from "../../../../utils/errorMessages";

yup.addMethod(yup.array, "unique", function (message, path) {
  return this.test("unique", message, function (list) {
    const mapper = x => x.mapFieldName;
    const set = [...new Set(list.map(mapper))];
    const isUnique = list.length === set.length;
    if (isUnique) {
      return true;
    }

    const idx = list.findIndex((l, i) => mapper(l) !== set[i]);
    return this.createError({
      path: `fields[${idx}].mapFieldName`,
      message: message,
    });
  });
});

let singleSchema = yup.object().shape({
  fields: yup
    .array()
    .of(
      yup.object().nullable().shape(
        {
          mapFieldName: yup.string().nullable().max(50, "Market field max length is 50").required("Market field name is required"),
          order: yup.number(),
          mpaFieldId: yup
            .object()
            .shape({
              label: yup.string(),
              value: yup.string(),
            })
            .nullable() // for handling null value when clearing options via clicking "x"
            .required("Master field mapping is required")
        })
    )
    .unique("Name already used")
    .default([])
});

const StepTwo = ({
  isLoading,
  setCreateFields,
  saveAsDraft,
  previousStep,
  getMPAFields,
  mpaFields,
  createData,
  createFlow,
  updateDraft,
  cloneFlow,
  selectedTemplate,
  getQRSpecification,
}) => {
  const history = useHistory();
  const [options, setOptions] = useState([]);

  const {
    register,
    handleSubmit,
    control,
    setValue,
    reset,
    watch,
    getValues,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      loadState: "unloaded",
      fields: []
    },
    resolver: yupResolver(singleSchema),
    mode: "all",
    reValidateMode: 'onChange'
  });

  const { fields, append, remove, swap, insert } = useFieldArray(
    {
      control,
      name: "fields"
    }
  );

  const watchFieldArray = watch("fields");
  const controlledFields = fields.map((field, index) => {
    return {
      ...field,
      ...watchFieldArray[index]
    };
  });

  const findParentLabel = (key, mpaFields) => {
    if (key === null) {
      return '';
    } else {
      const found = mpaFields.filter(x => x.value === key)
      if (found.length > 0) {
        return found[0].label
      }
      return '';
    }
  }

  useEffect(() => {
    async function fetchMPAFields() {
      try {
        isLoading(true);
        if (createData.fields.length > 0) {
          // flatten the array (consider child as same level)
          const parsedFields=createData.fields.map((x)=>{
            return {...x,
              mpaFieldId: {
                label: x.mpaParentKey!==null ? findParentLabel(x.mpaKey, mpaFields) : x.mpaFieldName,
                value: x.mpaKey,
                mpaFieldId: x.mpaFieldId
              }
            }
          });
          reset({
            fields: parsedFields
          })
        }else if(createData.qrFormatType === "String"){
          reset({
            fields: [{
              order: 0,
              mpaKey: null,
              mpaFieldId: null,
              mpaFieldName: null,
              mapFieldId: null,
              mpaParentKey: null,
              mapFieldName: null,
              qrFormatType: createData.qrFormatType,
              validations: {
                required: null,
                format: null,
                lengthType: null,
                lengthMin: null,
                lengthMax: null
              },
              defaultValue: null,
              valueMap: null,
              rules: null,
              requiredUserInput: null,
              children: []
            }]
          })
        } else {
          reset({
            fields: [{
              order: 0,
              mpaKey: null,
              mpaFieldId: null,
              mpaFieldName: null,
              mapFieldId: null,
              mpaParentKey: null,
              mapFieldName: null,
              qrFormatType: createData.qrFormatType,
              validations: {
                required: null,
                format: null,
                lengthType: null,
                lengthMin: null,
                lengthMax: null
              },
              defaultValue: null,
              valueMap: null,
              rules: null,
              requiredUserInput: null,
              children: []
            }, {
              order: 1,
              mpaKey: null,
              mpaFieldId: null,
              mpaFieldName: null,
              mapFieldId: null,
              mpaParentKey: null,
              mapFieldName: null,
              qrFormatType: createData.qrFormatType,
              validations: {
                required: null,
                format: null,
                lengthType: null,
                lengthMin: null,
                lengthMax: null
              },
              defaultValue: null,
              valueMap: null,
              rules: null,
              requiredUserInput: null,
              children: []
            }
            ]
          })
        }
        setOptions(mpaFields);
        isLoading(false);
      } catch (e) {
        if (e.response) {
          if (e.response.status) {
            if (e.response.status === 403) {
              toast.error(UNAUTHORIZED_ACTION);
            } else {
              toast.error(SOMETHING_WENT_WRONG)
            }
          } else if (e.response.data.Description) {
            toast.error(e.response.data);
          }
        } else {
          toast.error(SOMETHING_WENT_WRONG);
        }
        isLoading(false);
      }
    }
    fetchMPAFields();

  }, [reset, getMPAFields, mpaFields, previousStep, createData, isLoading])

  const findFieldNameByKey = (key, validations) => {
    const found = options.filter(x => x.value === key);
    if (found.length > 0) {
      return { mpaFieldName: found[0].actualKey, mpaFieldId: found[0].mpaFieldId, mpaParentKey: found[0].mpaParentKey, validations: { ...validations, mpaFieldValidations: found[0].originalFieldValidations } }
    } else {
      return { mpaFieldName: null, mpaFieldId: null, mpaParentKey: null, validations: { ...validations, mpaFieldValidations: null } }
    }
  }

  const getParsedFields = (fields) => {
    const parsedFields = fields.map((x, index) => { return { ...x, mpaKey: x.mpaFieldId.value, ...findFieldNameByKey(x.mpaFieldId.value, x.validations), order: index + 1 } });
    return parsedFields;
  }

  const onSubmit = async (data, buttonType = "next") => {
    isLoading(true);

    let prevData = {};
    if (cloneFlow) {
      prevData = await getQRSpecification(selectedTemplate.id);
    }

    const parsedFields = getParsedFields(data.fields)
    if (buttonType === "saveAsDraft") {
      try {
        // attach child to parent (according to the indentation)
        let fieldsArr = [];

        parsedFields.forEach(field => {
          if (field.indented && field.indented === true) {
            const updated = { ...field }
            delete updated.indented;
            const parent = fieldsArr[fieldsArr.length - 1];
            parent.children.push(updated);
            fieldsArr[fieldsArr.length - 1] = parent;
          } else {
            field.children = [];
            fieldsArr.push(field);
          }
        });

        if (createFlow || cloneFlow) {
          if (cloneFlow) {
            await saveAsDraft({ 
              isClone: true,
              cloneName: prevData.data.template,
              specification: { ...createData, fields: fieldsArr, stage: "draft", draftStep: 2, status: "in-progress" } });
          }else{
            await saveAsDraft({ specification: { ...createData, fields: fieldsArr, stage: "draft", draftStep: 2, status: "in-progress" } });
          }
          
          toast.success("Saved as draft");
        } else {
          await updateDraft({ specification: { ...createData, id: selectedTemplate.id, fields: fieldsArr, stage: "draft", draftStep: 2, status: "in-progress" } });
          toast.success("Updated draft");
        }
        history.push('/settings/qr-specs');
      } catch (e) {
        if (e.response) {
          toast.error(e.response.data.Description);
        } else {
          toast.error(SOMETHING_WENT_WRONG);
        }
      } finally {
        isLoading(false);
      }
    } else if (buttonType === "next") {
      if(createData.qrFormatType === "String" && parsedFields.length>1) {
        toast.error("'String' QR format does not support multiple fields");
      }else if(createData.qrFormatType === "String" && parsedFields[0].indented){
        toast.error("'String' QR format does not support (indented) child fields");
      }else if(createData.qrFormatType !== "String" && parsedFields.length<2){
        toast.error(`'${createData.qrFormatType}' QR format requires at least 2 fields`);
      }else if(createData.qrFormatType !== "String" && parsedFields.length>100){
        toast.error(`${parsedFields.length} fields found, the maximum number of fields allowed is 100`);
      }else{
        setCreateFields(parsedFields)
        GoToStepThree();
      }
      isLoading(false);
    }
  };

  const goBack = () => {
    const fields = getValues().fields.map(x => {
      if (x.mpaFieldId === null) {
        return {
          ...x, mpaFieldId: {
            label: null,
            value: null,
          }
        }
      } else {
        return x;
      }
    })
    setCreateFields(getParsedFields(fields));
    history.push('/settings/qr-specs/create/step1');
  };

  const GoToStepThree = () => {
    history.push('/settings/qr-specs/create/step3');
  }

  const MoveUp = (currentIndex) => {
    if (currentIndex !== 0) {
      swap(currentIndex, currentIndex - 1);
    }
  }

  const MoveDown = (currentIndex) => {
    if (currentIndex !== fields.length - 1) {
      swap(currentIndex, currentIndex + 1);
    }
  }

  const handleRemoveRow = (index) => {
    remove(index);
    setOptions([...options])
  }

  const InsertAt = (at) => {
    insert(parseInt(at, 10), {
      order: at,
      mpaKey: null,
      mpaFieldId: null,
      mpaFieldName: null,
      mapFieldId: null,
      mpaParentKey: null,
      mapFieldName: null,
      qrFormatType: createData.qrFormatType,
      validations: {
        required: null,
        format: null,
        lengthType: null,
        lengthMin: null,
        lengthMax: null
      },
      defaultValue: null,
      valueMap: null,
      rules: null,
      requiredUserInput: null,
      children: []
    })
  }

  const AddRow = () => {
    append({
      order: fields.length,
      mpaKey: null,
      mpaFieldName: null,
      mpaFieldId: null,
      mpaParentKey: null,
      mapFieldName: null,
      qrFormatType: createData.qrFormatType,
      validations: {
        required: null,
        format: null,
        lengthType: null,
        lengthMin: null,
        lengthMax: null
      },
      defaultValue: null,
      valueMap: null,
      rules: null,
      requiredUserInput: null,
      children: []
    })
  }

  const renderFields = controlledFields.map((field, index) => {
    return (
      <tr key={field.order}>
        <span className="d-flex border-b">
          <td className="w-100">
            <Form.Group controlId="name">
              <Form.Control
                type="hidden"
                name={`fields[${index}].order`}
                {...register(`fields.${index}.order`, { required: true })}
                value={index}
              />
              <Form.Control
                className={field.indented ? "indent" : ""}
                data-testid="mapFieldName"
                {...register(`fields.${index}.mapFieldName`, { required: true })}
                autoComplete="off"
                placeholder="Field Name"
                isInvalid={errors.fields?.[index]?.mapFieldName}
              />
              <Form.Control.Feedback id="nameErrors" type="invalid" className={field.indented ? "indent-error" : ""}>
                {errors.fields?.[index]?.mapFieldName?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </td>
          <td className="w-100">
            <Form.Group>
              <Controller
                data-testid="masterFieldMappings"
                name={`fields[${index}].mpaFieldId`}
                control={control}
                render={({ field }) => {
                  const format = (e) => {

                    field.onChange(
                      { ...e }
                    );
                  }
                  return <Select
                    defaultValue={options[0]}
                    {...field}
                    isSearchable
                    options={options}

                    onChange={format}
                    styles={{
                      control: (provided, state) => (errors.fields?.[index]?.mpaFieldId ? {
                        ...provided, borderColor: 'red', '&:hover': {
                          borderColor: state.isFocused ?
                            '#ddd' : isValid ?
                              '#ddd' : 'red'
                        },
                      } : provided)
                    }}
                  />;
                }}
              />
              {errors.fields?.[index]?.mpaFieldId ? (
                <div>
                  <span className="text-danger">
                    {errors.fields?.[index]?.mpaFieldId?.message}
                  </span>
                </div>
              ) : null}
            </Form.Group>
          </td>
        </span>
        <td className="arrow-col">
          <div className="arrow-btn">
            <Button data-testid="upArrowButton" className="btn-up" autoFocus={false} onClick={() => MoveUp(index)}><img className="arrow" src={arrowup} alt="up arrow" /></Button>
            <Button data-testid="downArrowButton" className="btn-down" autoFocus={false} onClick={() => MoveDown(index)}><img className="arrow" src={arrowdown} alt="down arrow" /></Button>
          </div>
        </td>
        <td className="option-col">
          <MarketFieldActionMenu
            data-testid="marketFieldActionMenu"
            row={field}
            index={index}
            disableIndentation={createData.qrFormatType === "Piped" || createData.qrFormatType === "String"}
            isStringFormat={createData.qrFormatType === "String"}
            indentHierarchy={(e) => {
              setValue(
                `fields[${index}].indented`,
                true
              );
            }}
            unIndentHierarchy={(e) => {
              setValue(
                `fields[${index}].indented`,
                false
              );
            }}
            removeRow={(r) => handleRemoveRow(index, r)}
            addRowAt={(c) => InsertAt(c)}
            rowCount={controlledFields.length}
          />
        </td>
      </tr>
    );
  });

  const handleSaveDraft = (data) => {
    onSubmit(data, "saveAsDraft");
  }

  return (
    <div className="user-page fill-parameter nw-specification">
      <Row className="add-padding">
        <Col lg="8" md="12" sm="12" className="left">
          <div className="page-title-row">
            <div className="title">
              <div className="breadcrumb">QR Specifications / {createFlow ? "New" : "Edit"} Specification</div>
            </div>
          </div>

          <Form
            className="form-2"
            id="map-market-fields-form"
            onSubmit={handleSubmit((data) => onSubmit(data, "next"))}
          >
            <div className="white-bg">
              <div className="title">
                <div className="sub-title1">
                  Step 2 of 3
                </div>
                <div className="sub-title2">
                  Map Market Fields
                </div>
              </div>

              <Table responsive className={"p-5"} data-testid="step2-table">
                <thead className="w-100 border-b">
                  <tr className="w-100">
                    <span className="d-flex">
                      <th className="w-100">Market Fields</th>
                      <th className="w-100">Master Field Mappings</th>
                    </span>
                    <th> </th>
                  </tr>
                </thead>
                <tbody >{renderFields}</tbody>
              </Table>

              <Button
                disabled={createData.qrFormatType === "String"}
                data-testid="newFieldButton"
                variant="primary"
                className="btn-add btn-cancel mx-3"
                onClick={AddRow}
              >
                <MdAdd />
                New field
              </Button>
              {
                errors.fields ? (
                  <span className="wrap-error-message">
                    <div className="error-message">{errors.fields.message}</div>
                  </span>
                ) : null
              }

            </div>
            <div className="frm-btn btn-section">
              <Button
                data-testid="backButton"
                variant="primary"
                className="btn-link back-btn"
                id="back-button"
                onClick={goBack}
              >
                <MdKeyboardArrowLeft />
                Back
              </Button>
              <div>
                <Button
                  data-testid="saveAsDraft"
                  variant="primary"
                  className="btn-cancel mx-3"
                  name="saveAsDraft"
                  onClick={handleSubmit((data) => handleSaveDraft(data))}
                >
                  <FiSave />
                  Save as Draft
                </Button>

                <Button
                  data-testid="nextButton"
                  variant="primary"
                  name="next"
                  type="submit"
                >
                  Next
                  <MdKeyboardArrowRight />
                </Button>
              </div>
            </div>
          </Form>

        </Col>

      </Row>
    </div >
  );
};

const mapStateToProps = (state) => {
  return {
    createData: state.qrSpecifiction.createData,
    previousStep: state.qrSpecifiction.currentStep,
    mpaFields: state.qrSpecifiction.mpaFields,
    userGroups: state.profile?.groups,
    createFlow: state.qrSpecifiction.flow === "create",
    cloneFlow: state.qrSpecifiction.flow === "clone",
    selectedTemplate: state.qrSpecifiction.current,
    initialStep: state.qrSpecifiction.initialStep
  };
};

const mapDispatchToProps = (dispatch) => ({
  isLoading: (flag) => dispatch(showLoader(flag)),
  setCreateFields: (fields) => dispatch(setStepTwoFields(fields)),
  getMPAFields: () => dispatch(getMPAFields()),
  saveAsDraft: (specification) => dispatch(createQRSpecification(specification)),
  updateDraft: (specification) => dispatch(updateQRSpecification(specification)),
  getQRSpecification: (id) => dispatch(getQRSpecification(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StepTwo);
